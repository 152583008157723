import React from 'react';
import useForm from 'react-hook-form'
import 'bootstrap/dist/css/bootstrap.min.css';
import Logo from './miami-banner.gif';
import { Form, Button, Container, Col, Row } from 'react-bootstrap';
import * as yup from "yup";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

const advancedMatching = {}; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
const options = {
    autoConfig: true, // set pixel's autoConfig
    debug: true, // enable logs
};

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);

const campaign = urlParams.get('utm_campaign') || '';
const source = urlParams.get('utm_source') || '';
const medium = urlParams.get('utm_medium') || '';
const targetgroup = urlParams.get('utm_anzeigengruppe') || '';
const postType = urlParams.get('utm_Anzeige') || '';



console.log(queryString);


const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/


const schema = yup.object().shape({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    age: yup
        .number()
        .min(18)
        .max(99),
    email: yup
        .string()
        .email()
        .required(),
    telephone: yup
        .string()
        .matches(phoneRegExp, 'Phone number is not valid')
        .required(),
    country_residence: yup
        .string()
        .required(),
    country_navigation: yup
        .string()
        .required(),
    gdpr: yup
        .string()
        .required(),
    gdpr_1: yup
        .string()
        .required(),
    boat_show: yup
        .string()
        .required()
});

function App() {
    ReactPixel.init('515720058866383', advancedMatching, options);

    ReactPixel.pageView();

    ReactGA.initialize('UA-99053016-1');

    ReactGA.pageview(window.location.pathname + window.location.search);

    const { register, errors, handleSubmit } = useForm({
        validationSchema: schema
    })
    const onSubmit = (data, e) => {

        console.log(data);
        fetch('https://hooks.zapier.com/hooks/catch/4300857/odbm5ur/silent/', {
            method: 'POST',
            body: JSON.stringify(
                data
            ),
        });

        ReactPixel.track( 'CompleteRegistration')

        ReactGA.event({
            category: 'MiamiBoot',
            action: 'Lead'
        });

        alert('Thank you for submitting your information');
        e.target.reset();
        // you can use HTML standard reset() function, but it only reset inputs' value
    };
    console.dir(errors);

    return (
    <div className="App">
      <header className="App-header">
        <div>

            <Container>
            <Row>
                <Col>
                    <img src={Logo} width="100%" />

                    <h1>Miami International Boat Show Registration</h1>

                    <p className={"lead"}>
                        Greenline Yachts on <strong>a new location</strong> this year: Key Biscane Marine Stadium.
                    </p>
                    <Form onSubmit={handleSubmit(onSubmit)}>
                        <Form.Control type="hidden" name="boat_show" value="miami" ref={register} />
                        <Form.Control type="hidden" name="campaign" value={campaign} ref={register} />
                        <Form.Control type="hidden" name="source" value={source} ref={register} />
                        <Form.Control type="hidden" name="medium" value={medium} ref={register} />
                        <Form.Control type="hidden" name="target_group" value={targetgroup} ref={register} />
                        <Form.Control type="hidden" name="post_type" value={postType} ref={register} />

                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control name="firstName" ref={register} required />
                                    {errors.firstName && <p>{errors.firstName.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={6}>
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control name="lastName" ref={register} required />
                                    {errors.lastName && <p>{errors.lastName.message}</p>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="email">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control name="email" type="email" ref={register} required />
                                    {errors.email && <p>{errors.email.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={6}>
                                <Form.Group controlId="telephone">
                                    <Form.Label>Telephone</Form.Label>
                                    <Form.Control name="telephone" type="text" ref={register} required />
                                    {errors.telephone && <p>{errors.telephone.message}</p>}
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <Col xs={12} md={6}>
                                <Form.Group controlId="country_residence">
                                    <Form.Label>Country of residence</Form.Label>
                                    <Form.Control as="select" name="country_residence" ref={register}>
                                        <option value="Select Country">Select Country</option>
                                        <option value="France">France</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Canada (Quebec)">Canada (Quebec)</option>
                                        <option value="Canada British Columbia">Canada British Columbia</option>
                                        <option value="Canada Ontario">Canada Ontario</option>
                                        <option value="Chile">Chile</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="France">France</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Germany (Bodensee)">Germany (Bodensee)</option>
                                        <option value="Germany (PLZ 0,1,2,3)">Germany (PLZ 0,1,2,3)</option>
                                        <option value="Germany (PLZ 7, 8, 9)">Germany (PLZ 7, 8, 9)</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hrvatska">Hrvatska</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Rest of the World">Rest of the World</option>
                                        <option value="Russia">Russia</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Spain (mainland)">Spain (mainland)</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="USA">USA</option>
                                        <option value="USA (Chicago)">USA (Chicago)</option>
                                        <option value="USA (FL Fort Lauderdale)">USA (FL Fort Lauderdale)</option>
                                        <option value="USA (FL St Petersburg)">USA (FL St Petersburg)</option>
                                        <option value="USA (Maryland)">USA (Maryland)</option>
                                        <option value="USA (Newport Beach, CA)">USA (Newport Beach, CA)</option>
                                        <option value="USA (Washington State)">USA (Washington State)</option>
                                        <option value="Select Country">Select Country</option>
                                    </Form.Control>
                                    {errors.country_residence && <p>{errors.country_residence.message}</p>}
                                </Form.Group>
                            </Col>

                            <Col xs={12} md={6}>

                                <Form.Group controlId="country_navigation">
                                    <Form.Label>Navigation area</Form.Label>
                                    <Form.Control as="select" name="country_navigation" ref={register}>
                                        <option value="Select Area">Select Area</option>
                                        <option value="USA">USA</option>
                                        <option value="USA (Chicago)">USA (Chicago)</option>
                                        <option value="USA (FL Fort Lauderdale)">USA (FL Fort Lauderdale)</option>
                                        <option value="USA (FL St Petersburg)">USA (FL St Petersburg)</option>
                                        <option value="USA (Maryland)">USA (Maryland)</option>
                                        <option value="USA (Newport Beach, CA)">USA (Newport Beach, CA)</option>
                                        <option value="USA (Washington State)">USA (Washington State)</option>
                                        <option value="France">France</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Austria">Austria</option>
                                        <option value="Belgium">Belgium</option>
                                        <option value="Canada (Quebec)">Canada (Quebec)</option>
                                        <option value="Canada British Columbia">Canada British Columbia</option>
                                        <option value="Canada Ontario">Canada Ontario</option>
                                        <option value="Chile">Chile</option>
                                        <option value="Croatia">Croatia</option>
                                        <option value="Czech Republic">Czech Republic</option>
                                        <option value="Denmark">Denmark</option>
                                        <option value="Estonia">Estonia</option>
                                        <option value="France">France</option>
                                        <option value="Georgia">Georgia</option>
                                        <option value="Germany">Germany</option>
                                        <option value="Germany (Bodensee)">Germany (Bodensee)</option>
                                        <option value="Germany (PLZ 0,1,2,3)">Germany (PLZ 0,1,2,3)</option>
                                        <option value="Germany (PLZ 7, 8, 9)">Germany (PLZ 7, 8, 9)</option>
                                        <option value="Greece">Greece</option>
                                        <option value="Hong Kong">Hong Kong</option>
                                        <option value="Hrvatska">Hrvatska</option>
                                        <option value="Hungary">Hungary</option>
                                        <option value="Italy">Italy</option>
                                        <option value="Japan">Japan</option>
                                        <option value="Kuwait">Kuwait</option>
                                        <option value="Lithuania">Lithuania</option>
                                        <option value="Malta">Malta</option>
                                        <option value="Netherlands">Netherlands</option>
                                        <option value="New Zealand">New Zealand</option>
                                        <option value="Norway">Norway</option>
                                        <option value="Portugal">Portugal</option>
                                        <option value="Rest of the World">Rest of the World</option>
                                        <option value="Russia">Russia</option>
                                        <option value="Serbia">Serbia</option>
                                        <option value="Slovakia">Slovakia</option>
                                        <option value="Slovenia">Slovenia</option>
                                        <option value="Spain">Spain</option>
                                        <option value="Spain (mainland)">Spain (mainland)</option>
                                        <option value="Sweden">Sweden</option>
                                        <option value="Switzerland">Switzerland</option>
                                        <option value="Thailand">Thailand</option>
                                        <option value="Turkey">Turkey</option>
                                        <option value="Ukraine">Ukraine</option>
                                        <option value="United Kingdom">United Kingdom</option>

                                        <option value="Select Area">Select Area</option>
                                    </Form.Control>
                                    {errors.country_navigation && <p>{errors.country_navigation.message}</p>}
                                </Form.Group>

                            </Col>
                        </Row>

                        <Form.Group>
                            <p>What dates are you visiting the Miami International Boat Show?</p>

                            {['checkbox'].map(type => (
                                <div key={`inline-${type}`} className="mb-3">
                                    <Form.Check inline name="dates" label="13.02.2020" value="13.02.2020" type={type} id={`inline-${type}-1`} ref={register} />
                                    <Form.Check inline name="dates" label="14.02.2020" value="14.02.2020" type={type} id={`inline-${type}-2`} ref={register} />
                                    <Form.Check inline name="dates" label="15.02.2020" value="15.02.2020" type={type} id={`inline-${type}-3`} ref={register} />
                                    <Form.Check inline name="dates" label="16.02.2020" value="16.02.2020" type={type} id={`inline-${type}-4`} ref={register} />
                                    <Form.Check inline name="dates" label="17.02.2020" value="17.02.2020" type={type} id={`inline-${type}-5`} ref={register} />
                                </div>
                            ))}


                        </Form.Group>

                        <Form.Group>
                            {['checkbox'].map(type => (
                                <div key={`gdpr-${type}`} className="mb-3">
                                    <div>
                                    <Form.Check required inline name="gdpr" label="" type={type} id={`gdpr-${type}-1`} ref={register} />
                                    <a href="https://www.greenlinehybrid.si/data-protection-policy/">Accept our Privacy Policy</a>
                                    </div>
                                    <div>
                                        <Form.Check required inline name="gdpr_1" label="" type={type} id={`gdpr-${type}-2`} ref={register} />
                                    <span>I agree that my data will be shared with the contractual greenline distributor close to my country of residence or country of navigation</span>
                                    </div>
                                </div>

                            ))}
                        </Form.Group>

                        <Button variant="primary" type="submit" variant="secondary" size="lg">
                            Register
                        </Button>

                        <p className={"mt-2"}>For more information on our models, visit <a href={"https://greenlinehybrid.si"} title={"GreenLine Yachts Website"}>www.greenlinehybrid.com</a></p>

                    </Form>
                </Col>
            </Row>
            </Container>

            <Container fluid={true} className={"footer"}>
                <Col xs={12}>
                <a className={"text-center"} href="https://www.greenlinehybrid.si/">
                    <img className="preload-me" src="https://www.greenlinehybrid.si/wp-content/uploads/2017/12/greenline_yachts_logo_28.png"
                                                                            width="28" height="28"
                                                                            alt="Greenline Yachts" />
                    Copyright © 2020 Greenline Yachts
                </a>
                </Col>
            </Container>

        </div>
      </header>
    </div>
  );
}

export default App;
